import React from "react"

import Layout from "../components/layout"
import ProjectNav from "../components/project-nav"
import Seo from "../components/seo"
import posterPlain from "../images/dan-flavin/poster-plain.png"
import posterSignage from "../images/dan-flavin/poster-signage.jpg"

import alternatives from "../images/dan-flavin/alternatives.jpg"
import portrait from "../images/dan-flavin/portrait.jpg"

import inspiration1 from "../images/dan-flavin/inspiration1.jpg"
import inspiration2 from "../images/dan-flavin/inspiration2.jpg"
import inspiration3 from "../images/dan-flavin/inspiration3.jpg"

const SecondPage = () => (
  <Layout>
    <Seo title="Dan Flavin" />
    <div className="light-grey">
      <ProjectNav className="small-space-top" previous="/ehb" next="/quinova" />
      <div className="content grid grid--2 space smaller-space-top">
        <div className="col-1">
          <h2>
            Dan Flavin
            <br />
            Poster Design
          </h2>
          <p className="teaser-text">
            For an exhibition of Dan Flavin's work at Kunsthalle Bern this
            Poster Design was designed to match the artist's characteristic
            style as well as inform about the date and location of the event.
          </p>
        </div>
        <img className="col-1" src={posterPlain} alt="Poster" />
      </div>
    </div>
    <div className="content space">
      <img className="space-below" src={posterSignage} alt="Poster Signage" />
      <div className="grid space-below">
        <div className="col-1">
          <h3>Variations</h3>
        </div>
        <img className="col-2" src={alternatives} alt="Alternative Designs" />
      </div>
      <div className="grid">
        <div className="col-1">
          <h3>Inspiration</h3>
        </div>
        <div className="col-2">
          <blockquote>
            <img
              className="round"
              src={portrait}
              alt="Portrait of Dan Flavin"
            />
            <div>
              <p className="quote-text">
                One might not think of light as a matter of fact, but I do. And
                it is, as I said, as plain and open and direct an art as you
                will ever find.
              </p>
              <p>
                <cite>– Dan Flavin</cite>
              </p>
            </div>
          </blockquote>
        </div>
        <div className="col-start-2 col-1">
          <img className="shadow" src={inspiration2} alt="Inspiration 1" />
        </div>
        <div className="col-1">
          <img className="shadow" src={inspiration1} alt="Inspiration 2" />
        </div>
        <div className="col-start-2 col-end">
          <img className="shadow" src={inspiration3} alt="Inspiration 3" />
        </div>
      </div>
    </div>
    <ProjectNav previous="/ehb" next="/quinova" className="space-below" />
  </Layout>
)

export default SecondPage
